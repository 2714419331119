import * as React from 'react';
import * as s from './styles/styles.module.scss';
import logo from 'src/static/images/icons/logo.svg';
import logo_white_text from 'src/static/images/icons/logo_white_text.svg';
import AnchorLink from 'react-anchor-link-smooth-scroll';

type PropTypes = {
  mode: 'header' | 'footer';
}

const Logo: React.FC<PropTypes> = ({ mode }) => {
  return (
    <div className={s.container}>
      {mode === 'header' ? (
        <div className={s.logo_header}>
          <AnchorLink href={'#/'}><img src={logo} alt=""/></AnchorLink>
        </div>       
      ) : (
        <div className={s.logo_footer}>
          <AnchorLink href={'#/'}><img src={logo_white_text} alt=""/></AnchorLink>
        </div>
      )}
    </div>
  );
};

export default React.memo(Logo);