import * as React from 'react';
import * as s from './styles/styles.module.scss';
import { cityItems } from './types';
import { YMaps, Map, Placemark, FullscreenControl, ZoomControl } from 'react-yandex-maps';

const MapsBlock: React.FC = () => {
  const [coords, setCoords] = React.useState<[number, number][]>(
    cityItems.map((item) => item.coordinates).filter((item, index) => index === 0),
  );

  React.useEffect(() => {
    setTimeout(() => {
      setCoords(cityItems.map((item) => item.coordinates));
    }, 100);
  }, []);

  return (
    <div className={s.container}>
      {cityItems.map((city, index) => (
        <div className={s.item_wrapper} key={city.city}>
          <div className={s.item}>
            <div className={s.city}>{city.city}</div>
            <a className={s.number} href={`tel:${city.number.split(' ').join('-')}`}>
              {city.number.split(' ').join('-')}
            </a>
            <div className={s.address}>{city.address}</div>
            <a className={s.email} href={`mailto:${city.email}`}>
              {city.email}
            </a>
            <div className={s.image}>
              {Boolean(coords[index]) && (
                <YMaps>
                  <Map defaultState={{ center: coords[index], zoom: 12 }} className={s.map}>
                    <Placemark defaultGeometry={coords[index]} />
                    <FullscreenControl />
                    <ZoomControl options={{ float: 'right' }} />
                  </Map>
                </YMaps>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default React.memo(MapsBlock);
