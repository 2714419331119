type ServicesBlockItemType = {
  title: string;
}

export const servicesBlockItems: ServicesBlockItemType[] = [
  {
    title: 'Проектирование инфраструктуры',
  },
  {
    title: 'Комплексные проекты “под ключ”',
  },
  {
    title: 'Поставка оборудования и ПО',
  },
  {
    title: 'Монтаж и пуско-наладочные работы',
  },
  {
    title: 'Управление качеством',
  },
  {
    title: 'Техническая поддержка',
  },
];