type SoftwareSolutionsBlockItemType = {
  title: string;
}

export const softwareSolutionsBlockItems: SoftwareSolutionsBlockItemType[] = [
  {
    title: 'Ситуационные центры',
  },
  {
    title: 'Управление транспортом',
  },
  {
    title: 'Мобильные приложения',
  },
  {
    title: 'Безопасный город',
  },
  {
    title: 'Техническое обслуживание и ремонт',
  },
  {
    title: 'Медицинские информационные системы',
  },
];