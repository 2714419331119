type CityItemType = {
  city: string;
  coordinates: [number, number];
  number: string;
  address: string;
  email: string;
};

export const cityItems: CityItemType[] = [
  {
    city: 'Москва',
    coordinates: [55.747115, 37.539087],
    number: '+7 495 055 43 31',
    address: '125039, Москва г., Пресненская Наб., д. 10, стр. 2',
    email: 'info@indor.tech',
  },
];
