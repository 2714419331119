import * as React from 'react';
import BlockWrapper from 'src/layouts/BlockWrapper';
import * as s from './styles/styles.module.scss';

const DESKTOP = '650x200';
const MOBILE = '240x400';

const DitMoscowWidget: React.FC = () => {
  const widgetRef = React.useRef(null);
  const [isShow, setIsShow] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setIsShow(true);
    }, 200);
  }, []);

  if (isShow) {
    return (
      typeof window !== 'undefined' && (
        <>
          <BlockWrapper className={s.background}>
            <div className="container">
              <div className={s.wrap}>
                <div id="widget1" className={s.widget} ref={widgetRef} />
              </div>
            </div>
          </BlockWrapper>

          {widgetRef && (
            <script>
              {(function (d, s): void {
                const fjs = d.getElementsByTagName(s)[0];
                const js = d.createElement(s);
                // @ts-expect-error:
                js.src = 'https://widgets.mos.ru/cnews/citywidgets.js';
                fjs.parentNode.insertBefore(js, fjs);
                const i = setInterval(function () {
                  // @ts-expect-error:
                  if (window.city_widget && document.readyState === 'complete') {
                    // @ts-expect-error:
                    city_widget(d.documentElement.offsetWidth < 768 ? MOBILE : DESKTOP, '#widget1');
                    clearInterval(i);
                  }
                }, 50);
                setTimeout(function () {
                  clearInterval(i);
                }, 5000);
              })(document, 'script')}
              ;
            </script>
          )}
        </>
      )
    );
  }
  return null;
};
export default React.memo(DitMoscowWidget);
