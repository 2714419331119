import * as React from 'react';
import * as s from './styles/styles.module.scss';
import BlockTitle from '../BlockTitle';
import BlockWrapper from 'src/layouts/BlockWrapper';
import BlockDescription from '../BlockDescription';

const AttestationBlock: React.FC = () => (
  <BlockWrapper className={s.container}>
    <div id={'attestation'} className={'anchor_link'} />
    <BlockTitle mode={'title'} classname={s.subtitle}>
      Аккредитация IT-компаний
    </BlockTitle>
    <BlockTitle mode={'title'} classname={s.title}>
      В соответствии с п.п. «г» п. 4 Положения о государственной аккредитации российских организаций, осуществляющих
      деятельность в области информационных технологий (утв. Постановлением Правительства РФ от 30.09.2022 № 1729)
      сообщаем следующую информацию.
    </BlockTitle>
    <BlockDescription>
      <p>
        Основным видом экономической деятельности (согласно Общероссийскому классификатору видов экономической
        деятельности) Общества с ограниченной ответственностью «Индор Технологии» (ООО «Индор Технологии», ОГРН
        1167746873524, ИНН 7725331972, адрес: 123112, г. Москва, вн.тер.г. муниципальный округ Пресненский, Пресненская
        Наб., д. 10, стр. 2, помещ. 5Н) является 62.01 Разработка компьютерного программного обеспечения. ООО «Индор
        Технологии» осуществляет следующие направления деятельности:
      </p>
      <ul>
        <li>
          проектирование, обследование, разработка, адаптация, модификация, модернизация, обновление, установка,
          интеграция, настройка, конфигурирование, внедрение, сопровождение, тестирование, испытания, техническая
          поддержка, эксплуатация, включая администрирование, а также оказание консультационных и экспертных услуг в
          указанных областях в отношении программ для электронных вычислительных машин, баз данных, визуальных
          пользовательских интерфейсов независимо от класса и вида программного обеспечения, информационных систем,
          сайтов или страниц сайтов в информационно-телекоммуникационной сети, информационно-телекоммуникационной
          структуры;
        </li>
        <li>
          деятельность, связанная с созданием баз данных и информационных ресурсов, формирование, ведение,
          администрирование баз данных, поиск данных, их отбор и сортировка по запросам, предоставление отобранных
          данных пользователям, в том числе в режиме непосредственного доступа;
        </li>
        <li>
          иные виды деятельности, относящиеся к деятельности в области информационных технологий, в том числе
          предусмотренные кодами 1.01 – 1.08, 3.01, 4.01, 6.01, 9.01, 11.01 в соответствии с Приказом Минцифры России от
          11.05.2023 № 449 «Об утверждении перечня видов деятельности в области информационных технологий».
        </li>
      </ul>
      <p>
        При разработке ПО используются такие языки программирования как Go (Golang), PHP, PowerScript, C/C++,
        JavaScript, Python. Мы используем GO с MVC фреймворком revel. На стороне frontend мы используем кроссбраузерный
        js-фреймворк Webix, который позволяет быстро создавать мобильные и настольные веб-приложения. Также во frontend
        используется JQuery, нативные html и js. Наши приложения используют такие СУБД как PostgreSQL, Oracle, MySQL и
        SQLite. Внутренние процессы автоматизируются скриптами на Python и Perl. Для создания инсталляторов под Windows
        используем InnoSetup. Приложения, разработанные нашей компанией, создаются с поддержкой Windows и Linux.
      </p>
    </BlockDescription>
  </BlockWrapper>
);

export default AttestationBlock;
