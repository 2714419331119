import * as React from 'react';
import * as s from './styles/styles.module.scss';
import BlockTitle from '../BlockTitle';
import BlockDescription from '../BlockDescription';
import { aboutBlockItems } from './types';
import AboutBlockItem from '../AboutBlockItem';
import BlockWrapper from 'src/layouts/BlockWrapper';

const AboutBlock: React.FC = () => (
  <BlockWrapper className={s.container}>
    <div id={'about'} className={'anchor_link'}/>
    <div className={s.titles}>
      <BlockTitle mode={'title'} classname={s.title}>О компании</BlockTitle>
      <BlockTitle mode={'subtitle'} classname={s.title}>Более 4 лет опыта</BlockTitle>
      <BlockDescription classname={s.description}>
        Выполняем работы силами своих разработчиков,
        за квалификацию и качество работы которых мы отвечаем.
      </BlockDescription>
    </div>
    <div className={s.items}>
      {aboutBlockItems.map((item) => (
        <div className={s.item} key={item.title}>
          <AboutBlockItem
            image={item.image}
            title={item.title}
            description={item.description}
          />
        </div>
      ))}
    </div>
  </BlockWrapper>
);

export default React.memo(AboutBlock);