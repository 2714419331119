import * as React from 'react';
import * as s from './styles/styles.module.scss';
import classNames from 'classnames';
import okayIcon from 'src/static/images/icons/okay_icon.svg';

type PropTypes = {
  title: string;
  classname?: string;
}

const SoftwareSolutionsBlockItem: React.FC<PropTypes> = ({ title, classname }) => (
  <div className={classNames(s.container, classname)}>
    <div className={s.icon}>
      <img src={okayIcon} alt=""/>
    </div>
    <div className={s.title}>{title}</div>
  </div>
);

export default React.memo(SoftwareSolutionsBlockItem);