type CareerBlockItemType = {
  title: string;
}

export const careerBlockItems: CareerBlockItemType[] = [
  {
    title: 'Наши главные ценности - ответственность, осмысленность и результативность. ',
  },
  {
    title: 'Экспертный уровень команды позволит вам расти как специалисту.',
  },
  {
    title: 'Работа в команде без лишних бюрократических процедур и внешнего контроля.',
  },
];

export enum VacanciesCity {
  MOSCOW = 'Москва',
  KRASNODAR = 'Краснодар',
  YAROSLAVL = 'Ярославль',
  UNKNOWN_CITY = 'unknown_city'
}

export type VacanciesRequestType = {
  items: {
    area: {
      name: VacanciesCity,
    },
    name: string;
    alternate_url: string;
  }[]
}

export type VacanciesType = {
  [VacanciesCity.MOSCOW]?: {
    name: string;
    url: string;
  }[];
  [VacanciesCity.KRASNODAR]?: {
    name: string;
    url: string;
  }[];
  [VacanciesCity.YAROSLAVL]?: {
    name: string;
    url: string;
  }[];
  [VacanciesCity.UNKNOWN_CITY]?: {
    name: string;
    url: string;
  }[];
}