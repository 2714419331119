import * as React from 'react';
import * as s from './styles/styles.module.scss';
import BlockTitle from '../BlockTitle';
import { softwareSolutionsBlockItems } from './types';
import SoftwareSolutionsBlockItem from '../SoftwareSolutionsBlockItem';
import BlockWrapper from 'src/layouts/BlockWrapper';

const SoftwareSolutionsBlock: React.FC = () => (
  <BlockWrapper className={s.container}>
    <div id={'softwareSolutions'} className={'anchor_link'}/>
    <BlockTitle mode={'title'} classname={s.title}>Программные решения</BlockTitle>
    <BlockTitle mode={'subtitle'} classname={s.subtitle}>Программные решения</BlockTitle>
    <div className={s.items}>
      {softwareSolutionsBlockItems.map((item) => (
        <div className={s.item} key={item.title}>
          <SoftwareSolutionsBlockItem
            title={item.title}
          />
        </div>
      ))}
    </div>
  </BlockWrapper>
);

export default React.memo(SoftwareSolutionsBlock);
