import ocs from 'src/static/images/logo/ocs.png';
import cryptopro from 'src/static/images/logo/cryptopro.png';
import netwell from 'src/static/images/logo/netwell.png';
import dit_msc from 'src/static/images/logo/dit_msc.png';

type PartnersType = {
  image?: string;
  link: string;
};

export const partners: PartnersType[] = [
  {
    image: ocs,
    link: 'https://www.ocs.ru',
  },
  {
    image: cryptopro,
    link: 'https://www.cryptopro.ru',
  },
  {
    image: netwell,
    link: 'https://www.netwell.ru',
  },
  {
    image: dit_msc,
    link: 'https://www.mos.ru/dit/',
  },
];
