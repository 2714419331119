import * as React from 'react';
import * as s from './styles/styles.module.scss';
import classNames from 'classnames';

type PropTypes = {
  title: string;
  classname?: string;
  onClickMoreInfo?: () => void;
}

const SystemIntegrationBlockItem: React.FC<PropTypes> = ({ title, classname }) => (
  <div className={classNames(s.container, classname)}>
    <div className={s.line} />
    <div className={s.title}>{title}</div>
  </div>
);

export default React.memo(SystemIntegrationBlockItem);
