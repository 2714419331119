import * as React from 'react';
import * as s from './styles/styles.module.scss';
import BlockTitle from '../BlockTitle';
import BlockDescription from '../BlockDescription';
import { careerBlockItems } from './types';
// import axios, { AxiosResponse } from 'axios';
import classNames from 'classnames';
import career_image from 'src/static/images/icons/career_image.svg';
import BlockWrapper from 'src/layouts/BlockWrapper';

const CareerBlock: React.FC = () => {
  // const [vacancies, setVacancies] = React.useState<VacanciesType>(null);

  // React.useEffect(() => {
  //   axios.get('https://api.hh.ru/vacancies?employer_id=3707634')
  //     .then((res: AxiosResponse<VacanciesRequestType>) => {
  //       const vac = res.data.items.reduce((accum: VacanciesType, item) => {
  //         const city = item.area?.name;
  //         const newVacancy = {
  //           name: item.name,
  //           url: item.alternate_url,
  //         };

  //         if (city) {
  //           if (accum[city]) {
  //             accum[city].push(newVacancy);
  //           } else {
  //             accum[city] = [newVacancy];
  //           }
  //         }

  //         return accum;
  //       }, {});

  //       setVacancies(vac);
  //     });
  // }, []);

  return (
    <BlockWrapper className={s.container}>
      <div id={'career'} className={'anchor_link'} />
      <div className={s.titles_wrapper}>
        <BlockTitle mode={'title'} classname={s.titles}>
          Карьера
        </BlockTitle>
        <BlockTitle mode={'subtitle'} classname={classNames(s.titles, s.subtitle)}>
          Мы молодая высокотехнологичная компания
        </BlockTitle>
        <img src={career_image} alt="" className={s.image} />
        <BlockDescription classname={s.description}>
          Наши главные ценности — ответственность, осмысленность и результативность. Работая с нами вы не будете тратить
          свое время на лишние процедуры объяснения. Вместо этого сможете взять ответственность за результат. Экспертный
          уровень команды позволит вам расти, а достижение целей позволит зарабатывать выше рынка и строить карьеру.
        </BlockDescription>
      </div>
      <div className={s.items}>
        {careerBlockItems.map((item) => (
          <div className={s.item} key={item.title}>
            <div className={s.item_text}>{item.title}</div>
          </div>
        ))}
      </div>
      {/* <BlockDescription classname={s.description_2}>
        Офис INDOORTECH располагается в историческом центре Москвы вблизи Покровского бульвара.
      </BlockDescription> */}
      {/* {vacancies && (
        <>
          <BlockTitle mode={'title'} classname={s.title_2}>Наши вакансии</BlockTitle>
          <div className={s.vacancies}>
            {Object.keys(vacancies).map((city: VacanciesCity) => (
              <div key={city} className={s.vacancies_block}>
                <div className={s.vacancies_city}>{city}</div>
                {vacancies[city].map((vacs) => (
                  <div key={vacs.name} className={s.vacancies_item}>
                    <a href={vacs.url} target={'__blank'}>{vacs.name}</a>
                  </div>
                ))}
              </div>
            ))}
          </div>
         </>
        )} */}
    </BlockWrapper>
  );
};

export default React.memo(CareerBlock);
