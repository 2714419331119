import * as React from 'react';
import * as s from './styles/styles.module.scss';
import Logo from '../Logo';
import WrappedContainer from 'src/layouts/WrappedContainer';

const Footer: React.FC = () => (
  <div className={s.wrapper_container}>
    <WrappedContainer>
      <div className={s.container}>
        <div className={s.logo_block}>
          <Logo mode={'footer'}/>
          <div className={s.copyright}>© 2016 - 2022 INDORTECH</div>
        </div>
        <div className={s.block}>
          <div className={s.block_item}>
            <div className={s.block_title}>По любым вопросам обращайтесь:</div>
            <div className={s.block_link}>тел.: <a href={'tel:+7-495-055-43-31'}>+7 (495) 055 43 31</a></div>
            <div className={s.block_link}>email: <a href={'mailto:info@indor.tech'}>info@indor.tech</a></div>
          </div>
          <div className={s.block_item}>
            <div className={s.block_title}>ООО «Индор Технологии»</div>
          </div>
        </div>
      </div>
    </WrappedContainer>
  </div>
);

export default React.memo(Footer);