import * as React from 'react';
import * as s from './styles/styles.module.scss';
import classNames from 'classnames';

type PropTypes = {
  classname?: string;
}

const DividingLine: React.FC<PropTypes> = ({ classname }) => (
  <div className={classNames(s.line, classname)} />
);

export default DividingLine;