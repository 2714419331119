import * as React from 'react';
import * as s from './styles/styles.module.scss';
import classNames from 'classnames';

type PropTypes = {
  image: React.ReactElement | string;
  title: string;
  description: string;
  classname?: string;
}

const AboutBlockItem: React.FC<PropTypes> = ({ image, title, description, classname }) => (
  <div className={classNames(s.container_wrapper, classname)}>
    <div>
      <img src={image as string} alt=""/>
    </div>
    <div className={s.title}>{title}</div>
    <div className={s.description}>{description}</div>
  </div>
);

export default React.memo(AboutBlockItem);