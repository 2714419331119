import * as React from 'react';
import * as s from './styles/styles.module.scss';
import classNames from 'classnames';
import AnchorLink from 'react-anchor-link-smooth-scroll';

type PropTypes = {
  mode: 'header' | 'footer';
}

const Navbar: React.FC<PropTypes> = ({ mode }) => {
  const [isShowGamburgerMenu, setIsShowGamburgerMenu] = React.useState(false);

  const handleShow = React.useCallback((isShow: boolean) => (): void => {
    setIsShowGamburgerMenu(isShow);
  }, []);

  const handleClickUl = React.useCallback((e: React.MouseEvent) => {
    const tagName = (e.target as HTMLUListElement).tagName;
    if (tagName === 'A' || tagName === 'LI') {
      setIsShowGamburgerMenu(false);
    }
  }, []);

  return (
    <div>
      <div className={s.gamburger_wrapper} onClick={handleShow(true)}>
        <div className={s.gamburger} />
      </div>
      {isShowGamburgerMenu && (
        <div
          className={s.nav_gamburger_closed}
          onClick={handleShow(false)}
        />
      )}
      <ul
        className={classNames({
          [s.container]: mode === 'header',
          [s.nav_gamburger]: isShowGamburgerMenu,
          [s.container_footer]: mode === 'footer',
        })}
        onClick={isShowGamburgerMenu ? handleClickUl : undefined}
      >
        <li className={s.item}><AnchorLink href={'#about'}>О компании</AnchorLink></li>
        <li className={s.item}><AnchorLink href={'#systemIntegration'}>Системная интеграция</AnchorLink></li>
        <li className={s.item}><AnchorLink href={'#softwareSolutions'}>Программные решения</AnchorLink></li>
        <li className={s.item}><AnchorLink href={'#partnersAndCustomers'}>Партнёры и заказчики</AnchorLink></li>
        <li className={s.item}><AnchorLink href={'#career'}>Карьера</AnchorLink></li>
        <li className={s.item}><AnchorLink href={'#contacts'}>Контакты</AnchorLink></li>
      </ul>
    </div>
  );
};

export default React.memo(Navbar);