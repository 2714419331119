import * as React from 'react';
import * as s from './styles/styles.module.scss';
import classNames from 'classnames';

type PropTypes = {
  children: React.ReactNode;
  classname?: string;
  mode: 'title' | 'subtitle';
}

const BlockTitle: React.FC<PropTypes> = ({ children, classname, mode }) => (
  <div className={classNames({
    [s.title]: mode === 'title',
    [s.subtitle]: mode === 'subtitle',
  }, classname)}>{children}</div>
);

export default React.memo(BlockTitle);