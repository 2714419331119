import * as React from 'react';
import * as s from './styles/styles.module.scss';
import image from 'src/static/images/icons/main_image.svg';
import BlockWrapper from 'src/layouts/BlockWrapper';
import WrappedContainer from 'src/layouts/WrappedContainer';

const MainInfo: React.FC = () => (
  <BlockWrapper className={s.container}>
    <WrappedContainer className={s.wrapper}>
      <div className={s.block}>
        <img className={s.image} src={image} alt="qwdq"/>
        <div className={s.title}>Разработка и поддержка программного обеспечения</div>
        <div className={s.desc}>
          INDOORTECH — системный интегратор, специализирующийся на решениях в области комплексной автоматизации,
          создания сетей передачи данных, ЦОД и систем безопасности для государственного сектора и крупных корпоративных клиентов
        </div>
      </div>
    </WrappedContainer>
  </BlockWrapper>
);

export default React.memo(MainInfo);