import * as React from 'react';
import * as s from './styles/styles.module.scss';
import BlockTitle from '../BlockTitle';
import BlockDescription from '../BlockDescription';
import MapsBlock from '../MapsBlock';
import classNames from 'classnames';
import BlockWrapper from 'src/layouts/BlockWrapper';

const ContactsBlock: React.FC = () => (
  <BlockWrapper className={s.container}>
    <div id={'contacts'} className={'anchor_link'} />
    <BlockTitle mode={'title'} classname={s.titles}>
      Контакты
    </BlockTitle>
    <BlockTitle mode={'subtitle'} classname={s.titles}>
      Свяжитесь с нами
    </BlockTitle>
    <BlockDescription classname={classNames(s.titles, s.description)}>
      Мы готовы рассмотреть любые Ваши потребности и найти нужный подход для решения поставленных задач. Предлагаем
      заполнить форму с Вашими контактами и мы обязательно вернемся в кратчайшие сроки.
    </BlockDescription>
    <BlockDescription classname={s.margin_bottom_10}>Позвоните нам</BlockDescription>
    <a className={s.number} href="tel:+7-495-055-43-31">
      +7 (495) 055-43-31
    </a>
    <MapsBlock />
  </BlockWrapper>
);

export default React.memo(ContactsBlock);
