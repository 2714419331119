import * as React from 'react';
import * as s from './styles/styles.module.scss';
import BlockTitle from '../BlockTitle';
import { partners } from './types';
import { useDeviceDetect } from 'src/utils/hooks';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import classNames from 'classnames';

const PartnersAndCustomersBlock: React.FC = () => {
  const { isMobile } = useDeviceDetect();

  const responsive = React.useMemo(() => ({
    desktop: {
      breakpoint: { max: Infinity, min: 1200 },
      items: 4,
      slidesToSlide: 4, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1199, min: 767 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 766, min: 500 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
  }), []);

  return (
    <div className={s.container}>
      <div id={'partnersAndCustomers'} className={'anchor_link'} />
      <BlockTitle mode={'title'} classname={s.title}>Партнеры и заказчики</BlockTitle>

      <div className={classNames(s.ticker_block, { [s.ticker_block_mobile]: isMobile })}>
        {isMobile ? (
          <div className={s.items_mobile}>
            {partners.map((item) => (
              <div className={s.item_wrapper} key={item.link}>
                <div className={s.item} >
                  <a href={item.link} target={'_blank'} rel={'noreferrer'}>
                    <img src={item.image} alt="" />
                  </a>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div>
            <Carousel
              swipeable={false}
              draggable={false}
              responsive={responsive}
              infinite={true}
              containerClass={s.carousel_container}
            >
              {partners.map((partner) => (
                <div className={s.item_wrapper} key={partner.link}>
                  <div className={s.item}>
                    <a href={partner.link} target={'_blank'} rel={'noreferrer'}>
                      <img src={partner.image} alt="" />
                    </a>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        )}
      </div>

      {/* <div className={s.widget}>
        <div id={'moscow'} className={'anchor_link'} />
        <DitMoscowWidget />
      </div> */}
    </div>
  );
};

export default React.memo(PartnersAndCustomersBlock);