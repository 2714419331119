import * as React from 'react';
import * as s from './styles/styles.module.scss';
import classNames from 'classnames';

type PropType = {
  children: React.ReactNode;
  className?: string;
};

const WrappedContainer: React.FC<PropType> = ({ children, className }) => {
  return (
    <div className={classNames(s.wrapped_container, className)}>
      {children}
    </div>
  );
};

export default React.memo(WrappedContainer);