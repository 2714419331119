import * as React from 'react';
import turnkeyProjectsIcon from 'src/static/images/icons/turnkey_projects.svg';
import qualityControlIcon from 'src/static/images/icons/quality_control.svg';
import designAndEngineeringIcon from 'src/static/images/icons/design_and_engineering.svg';
import outsourcingIcon from 'src/static/images/icons/outsourcing.svg';
import introductionIcon from 'src/static/images/icons/introduction.svg';
import auditAndTechnicalSupportIcon from 'src/static/images/icons/audit_and_technical_support.svg';

type AboutBlockItemType = {
  image: React.ReactElement;
  title: string;
  description: string;
}

export const aboutBlockItems: AboutBlockItemType[] = [
  {
    image: turnkeyProjectsIcon,
    title: 'Проекты «под ключ»',
    description: 'Мы выполняем проекты полного цикла, от ТЗ до передачи системы на сопровождение.',
  },
  {
    image: qualityControlIcon,
    title: 'Управление качеством',
    description: 'Мы предоставляем полный стек сервисов по управлению качеством, включающий все виды тестирования.',
  },
  {
    image: designAndEngineeringIcon,
    title: 'Дизайн и проектирование',
    description: 'Дизайн и проектирование информационных систем, в рамках проекта или как отдельный сервис. ',
  },
  {
    image: outsourcingIcon,
    title: 'Подготовка данных',
    description: 'Сбор данных для обучения и тестирования систем машинного обучения и компьютерного зрения.',
  },
  {
    image: introductionIcon,
    title: 'Внедрение',
    description: 'Мы готовы внедрить решения собственной разработки, а также партнерские сторонние решения.',
  },
  {
    image: auditAndTechnicalSupportIcon,
    title: 'Аудит и техподдержка',
    description: 'Выполняем работы по аудиту  программных решений и по техподдержке действующих ИТ-систем.',
  },
];