import * as React from 'react';
import * as s from './styles/styles.module.scss';
import BlockTitle from '../BlockTitle';
import { systemIntegrationBlockItems } from './types';
import SystemIntegrationBlockItem from '../SystemIntegrationBlockItem';
import BlockDescription from '../BlockDescription';
import BlockWrapper from 'src/layouts/BlockWrapper';

const SystemIntegrationBlock: React.FC = () => {
  return (
    <BlockWrapper className={s.container}>
      <div id={'systemIntegration'} className={'anchor_link'}/>
      <BlockTitle mode={'title'} classname={s.titles}>Системная интеграция</BlockTitle>
      <BlockTitle mode={'subtitle'} classname={s.titles}>Системная интеграция</BlockTitle>
      <BlockDescription classname={s.description}>
        Выполняем работы силами только своих разработчиков,
        за квалификацию и качество работы которых мы отвечаем.
      </BlockDescription>
      <div className={s.items}>
        {systemIntegrationBlockItems.map((item) => (
          <div className={s.item} key={item.title}>
            <SystemIntegrationBlockItem title={item.title} />
          </div>
        ))}
      </div>
    </BlockWrapper>
  );
};

export default React.memo(SystemIntegrationBlock);
