import * as React from 'react';
import WrappedContainer from 'src/layouts/WrappedContainer';
import Header from 'src/components/Header';
import Footer from 'src/components/Footer';
import DividingLine from 'src/components/DividingLine';
import MainInfo from 'src/components/MainInfo';
import AboutBlock from 'src/components/AboutBlock';
import SoftwareSolutionsBlock from 'src/components/SoftwareSolutionsBlock';
import SystemIntegrationBlock from 'src/components/SystemIntegrationBlock';
import ServicesBlock from 'src/components/ServicesBlock';
import CareerBlock from 'src/components/CareerBlock';
import PartnersAndCustomersBlock from 'src/components/PartnersAndCustomersBlock';
import DitMoscowBannerWidget from 'src/components/DitMoscowBannerWidget';
import ContactsBlock from 'src/components/ContactsBlock';
import PageLayout from 'src/layouts/Page';
import AttestationBlock from 'src/components/AttestationBlock';

interface IndexPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
}

const MainPage: React.FC<IndexPageProps> = () => {
  return (
    <PageLayout>
      <div id={'/'} />
      <Header />
      <MainInfo />
      <WrappedContainer>
        <DividingLine />
      </WrappedContainer>
      <WrappedContainer>
        <AboutBlock />
      </WrappedContainer>
      <WrappedContainer>
        <DividingLine />
      </WrappedContainer>
      <WrappedContainer>
        <SystemIntegrationBlock />
      </WrappedContainer>
      <WrappedContainer>
        <DividingLine />
      </WrappedContainer>
      <WrappedContainer>
        <SoftwareSolutionsBlock />
      </WrappedContainer>
      <WrappedContainer>
        <AttestationBlock />
      </WrappedContainer>
      <ServicesBlock />
      <WrappedContainer>
        <PartnersAndCustomersBlock />
      </WrappedContainer>
      <WrappedContainer>
        <DividingLine />
      </WrappedContainer>
      <WrappedContainer>
        <CareerBlock />
      </WrappedContainer>
      <WrappedContainer>
        <DividingLine />
      </WrappedContainer>
      <WrappedContainer>
        <DitMoscowBannerWidget />
      </WrappedContainer>
      <WrappedContainer>
        <ContactsBlock />
      </WrappedContainer>
      <Footer />
    </PageLayout>
  );
};

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default MainPage;
