type SystemIntegrationBlockItemsType = {
  title: string;
}

export const systemIntegrationBlockItems: SystemIntegrationBlockItemsType[] = [
  {
    title: 'Создание сетей передачи данных, включая ВКС и телефонию',
  },
  {
    title: 'Создание ЦОД на базе имеющейся инженерной инфраструктуры',
  },
  {
    title: 'Создание систем безопасности',
  },
];