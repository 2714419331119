import * as React from 'react';
import * as s from './styles/styles.module.scss';
import Logo from '../Logo';
import Navbar from '../Navbar';
import DividingLine from '../DividingLine';
import WrappedContainer from 'src/layouts/WrappedContainer';

const Header: React.FC = () => (
  <div>
    <div className={s.header_plug}/>
    <div className={s.header_fixed}>
      <WrappedContainer>
        <div className={s.container}>
          <Logo mode={'header'}/>
          <Navbar mode={'header'}/>
        </div>
      </WrappedContainer>
      <DividingLine />
    </div>
  </div>
);

export default React.memo(Header);