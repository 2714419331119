import * as React from 'react';
import { Helmet } from 'react-helmet';
import favicon from 'src/static/images/favicon/favicon.ico';

type PropType = {
  children: React.ReactNode;
};

const PageLayout: React.FC<PropType> = ({ children }) => {
  return (
    <>
      <Helmet>
        <title>Индор Технологии</title>
        <link rel="icon" href={favicon} />
      </Helmet>
      {children}
    </>
  );
};

export default React.memo(PageLayout);