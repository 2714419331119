import * as React from 'react';
import * as s from './styles/styles.module.scss';
import BlockTitle from '../BlockTitle';
import { servicesBlockItems } from './types';
import ServicesBlockItem from '../ServicesBlockItem';

const ServicesBlock: React.FC = () => {
  return (
    <div className={s.container}>
      <BlockTitle mode={'subtitle'} classname={s.subtitle}>Услуги компании</BlockTitle>
      <div className={s.items}>
        {servicesBlockItems.map((item) => (
          <div className={s.item} key={item.title}>
            <ServicesBlockItem
              title={item.title}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(ServicesBlock);
