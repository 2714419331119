import * as React from 'react';
import * as s from './styles/styles.module.scss';
import classNames from 'classnames';

type PropTypes = {
  children: React.ReactNode;
  classname?: string;
}

const BlockDescription: React.FC<PropTypes> = ({ children, classname }) => (
  <div className={classNames(s.description, classname)}>{children}</div>
);

export default React.memo(BlockDescription);